<template>
     <section>
    <div class="container">
      <div class="user signinBx">
        <div class="imgBx">

        <img src="/src/assets/monoryumLogoTam.png" alt="Monoryum E-Fatura" />
        </div>
        <div class="formBx">

        

         <form action="" autocomplete="off" >
           <h4>Şifremi Unuttum</h4>
           <h6>Sisteme kayıtlı olan E-Posta Adresinize yeni Şifreniz gönderilecektir.</h6>
             <input type="email" v-model="lostEmail" @blur="$v.lostEmail.$touch()" :class="{ 'invalidData': $v.lostEmail.$error }" placeholder="E-Posta" />
             
           
            <button type="button" :disabled="$v.$invalid" @click="sifreGonder" class="btn btn-block w-100 ">Yeni Şifre Gönder</button>
             
            <p class="signup">
              yada Giriş Yapmak için...
             <a href="#" @click="$router.push('/')" >Giriş Yap</a>
               
            </p>
          </form>
        </div>
      </div>
    
    </div>
  </section>
</template>

<script>
 import { required, email, minLength } from 'vuelidate/lib/validators'
 
export default ({
    data:function(){
        return {
            lostEmail:""
        }
    },
    validations:{
        lostEmail:{
            required,
            email
        }
    },
    methods:{

        sifreGonder(){
                    this.$store.state.loadAnimAc=true;
                    setTimeout(() => {
                        this.$store.dispatch("sifremiUnuttum",{maili:this.lostEmail});
                    }, 1000);

        }
    }
})
</script>



<style scoped>

h6{
    font-size: 12px;
}
.invalidData{

  border:1px solid red !important;
   
}

section {
  position: relative;
  min-height: 100vh;
  background-color: #670ca3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
 
}

section .container {
  position: relative;
  width: 800px;
  height: 500px;
  background: #fff;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
  overflow: hidden;
   border-radius: 20px;;
}

section .container .user {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

section .container .user .imgBx {
  position: relative;
  width: 50%;
  height: 100%;
  background: white;
  transition: 0.5s;
  display:flex;
  align-items:center;
  justify-content:center;
  text-align:center;
}

section .container .user .imgBx img {
  
   
  width: 50%;
  height: auto !important;
   
   
}

section .container .user .formBx {
  position: relative;
  width: 50%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  transition: 0.5s;
}

section .container .user .formBx form h2 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  color: #555;
}

section .container .user .formBx form input {
  position: relative;
  width: 100%;
  padding: 10px;
  background: #f5f5f5;
  color: #333;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 8px 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 300;
}


section .container .user .formBx form button[type='button'] {
  
  background: #670ca3;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.5s;
}
section .container .user .formBx form button[type='submit'] :disabled {
  
  background: lightgray;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.5s;
}
section .container .user .formBx form .signup {
  position: relative;
  margin-top: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  color: #555;
  text-transform: uppercase;
  font-weight: 300;
}
section .container .user .formBx form .lostPassDiv {
  position: absolute;
  bottom: 0;
  right: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  color: #555;
  text-transform: uppercase;
  font-weight: 300;
}
section .container .user .formBx form .lostPassDiv a {
  font-weight: 600;
  text-decoration: none;
  color: #677eff;
}

section .container .user .formBx form .signup a {
  font-weight: 600;
  text-decoration: none;
  color: #677eff;
}
 
section .container .signupBx {
  pointer-events: none;
}

section .container.active .signupBx {
  pointer-events: initial;
}

section .container .signupBx .formBx {
  left: 100%;
}

section .container.active .signupBx .formBx {
  left: 0;
}

section .container .signupBx .imgBx {
  left: -100%;
}

section .container.active .signupBx .imgBx {
  left: 0%;
}

section .container .signinBx .formBx {
  left: 0%;
}

section .container.active .signinBx .formBx {
  left: 100%;
}

section .container .signinBx .imgBx {
  left: 0%;
}

section .container.active .signinBx .imgBx {
  left: -100%;
}

@media (max-width: 991px) {
  section .container {
    max-width: 400px;
  }

  section .container .imgBx {
    display: none;
  }

  section .container .user .formBx {
    width: 100%;
  }
}

</style>
